export const addReciprocalEasementVisuals = [
  {
    name: "Demo",
    markdown: `![Add reciprocal easement GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addReciprocalEasement = {
  order: 15,
  name: "Add a reciprocal easement",
  keywords: "REA retail shopping center mall",
  subcategory: "Properties",
  markdown: `# Add a reciprocal easement

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  You can display a reciprocal easement on the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} to call attention to the shared physical access that is typically being granted. Displaying easements is also a helpful way to quickly navigate to the underlying {% inlineRouterLink %}documents that you can attach{% /inlineRouterLink %}.

  {% callout type="caution" marginStyles="mb-4" %}
  **Important**: This command saves **immediately**.
  {% /callout %}

  ## Steps

  {% tablessContainer marginStyles="mb-4" %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcel{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="insertReciprocalEasement" /%}.
  4. A new reciprocal easement appears below the selected fee parcel.
  {% /tablessContainer %}

  {% callout type="tip" %}
  **Tip**: To place more fee parcels within the scope of the reciprocal easement, {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**select the easement**{% /inlineRouterLink %} and then {% inlineRouterLink articleId="add-a-fee-parcel" %}add a fee parcel{% /inlineRouterLink %}.
  {% /callout %}

  ## Visuals

  {% tabbedContainer tabs=$addReciprocalEasementVisuals /%}
  `,
};
